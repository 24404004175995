<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import { useQRCode } from '@vueuse/integrations/useQRCode'
import Example from '@/components/example'
import Copy from '@/components/copy'
import { useSwap } from '@/pages-components/swap/hooks/useSwap'
import { fixed, inputMoney } from '@/utils'

const { config } = useAppState()
const course = useAssetOnLocale({
  zh: import('@/assets/imgs/b2c-screenshot-zh.png?url'),
  en: import('@/assets/imgs/b2c-screenshot-en.png?url'),
})
const platformAddress = computed(() => config?.value?.wallet?.transfer_exchange_address)
const enableExchange = computed(() => !!config?.value?.exchange?.enable_exchange)
const qrcode = useQRCode(platformAddress)
const {
  toCrypto,
  fromCrypto,
  switchCrypto,
  disable,
  clearValue,
  minExchangeAmount,
} = useSwap()!

const { copy, copied, isSupported } = useClipboard({ legacy: true, navigator: fromCrypto })

const formatAmountText = computed(() => formatAmount(fromCrypto.value, 6))

async function handleCopyAmount() {
  if (!fromCrypto.value) {
    aMessage.error($t('i14bip0GKuhvH-e076A56'))
    return
  }
  if (fromCrypto.value < minExchangeAmount.value) {
    aMessage.error(
      `${$t('zkve7RRrS8_5kyZhjYPcU')}${fromCrypto.info.minValue}`,
    )
    return
  }
  if (fromCrypto.code === toCrypto.code) {
    aMessage.error($t('KXpsGc7WeryuW8Ek4Cn_z'))
    return
  }
  copy(fromCrypto.value)
}

function shortcutInput(value) {
  fromCrypto.value = value
}
</script>

<template>
  <div class="transfer-pay text-[14px]">
    <div class="from-currency">
      <div class="balance">
        <span class="label text-[16px]">{{ $t("_X-tn_iR5wA0xAcbpbKCx") }}</span>
      </div>
      <div class="input">
        <AInputNumber
          v-model:value="fromCrypto.value"
          v-bind="inputMoney"
          :bordered="false"
          :controls="false"
          :placeholder="$t('Z3dE8F8H0GPEYq_juWdf5')"
          :precision="6"
          :max="fromCrypto.info.maxValue"
          :min="fromCrypto.info.minValue"
          :disabled="disable"
        >
          <template #addonAfter>
            <i
              v-if="!!fromCrypto.value"
              class="i-ant-design:close-circle-outlined input-clear"
              data-test-id="8cyg"
              @click="clearValue"
            />
          </template>
        </AInputNumber>
        <div class="select-currency">
          <ASelect v-model:value="fromCrypto.code" popupClassName="ant-cover__basic-select-dropdown" data-test-id="yMI6">
            <template #suffixIcon>
              <i class="i-ant-design:down-outlined select-down-icon" />
            </template>

            <ASelectOption
              v-for="item of fromCrypto.list"
              :key="item.code"
              :value="item.code"
            >
              <div class="flex items-center">
                <i class="text-[20px] mr-[5px]" :class="item.icon" />
                <span class="lt-mobilel:text-[14px]">{{ item.symbol }}</span>
              </div>
            </ASelectOption>
          </ASelect>
        </div>
      </div>
    </div>
    <div v-if="fromCrypto.info.shortcut.length" class="shortcut-select">
      <div
        v-for="(value, key) of fromCrypto.info.shortcut"
        :key="key"
        class="shortcut-select-value"
        data-test-id="ziqb"
        @click="shortcutInput(value)"
      >
        {{ value }}
      </div>
    </div>
    <i
      class="i-local:reverse-currency?mask switch-currency-button"
      data-test-id="aem5"
      @click="switchCrypto"
    />

    <div class="to-currency">
      <div class="balance">
        <span class="label text-[16px]">{{ $t("wZutznikQLic6i7sOz0J5") }}</span>
      </div>
      <div class="input">
        <AInputNumber
          v-bind="inputMoney"
          v-model:value="toCrypto.value"
          :placeholder="$t('Z3dE8F8H0GPEYq_juWdf5')"
          :bordered="false"
          :controls="false"
          :min="0"
          :precision="6"
          :max="toCrypto.info.maxValue"
          :disabled="disable"
        >
          <template #addonAfter>
            <i
              class="i-ant-design:close-circle-outlined input-clear"
              data-test-id="zenq"
              @click="clearValue"
            />
          </template>
        </AInputNumber>

        <div class="select-currency">
          <ASelect v-model:value="toCrypto.code" popupClassName="ant-cover__basic-select-dropdown">
            <template #suffixIcon>
              <i
                class="i-ant-design:down-outlined select-down-icon"
              />
            </template>
            <ASelectOption
              v-for="item of toCrypto.list"
              :key="item.code"
              :value="item.code"
            >
              <div class="flex items-center">
                <i class="text-[20px] mr-[5px]" :class="item.icon" />
                <span class="lt-mobilel:text-[14px]">{{ item.symbol }}</span>
              </div>
            </ASelectOption>
          </ASelect>
        </div>
      </div>
    </div>
    <div class="teaching-link">
      <span>{{ $t("5ze20eNcSmR_sOFbVJizT") }} </span>
      <Example>
        <span class="example-title">{{ $t("lKId_lNIZndqd7LEmRaRs") }}</span>
        <template #content>
          <img class="example-img" :src="course">
        </template>
      </Example>
    </div>

    <div class="pay-info">
      <div class="content">
        <div class="content-text">
          <span class="address-label">{{ $t("Xf0lCI5iXjby5btO0do4c") }}
            （<span v-if="currentLocale === 'zh'">最低 {{ config?.exchange.usdt_min_exchange_amount }} USDT & {{ config?.exchange.trx_min_exchange_amount }} TRX 起</span>
            <span v-else>start at {{ config?.exchange.usdt_min_exchange_amount }} USDT & {{ config?.exchange.trx_min_exchange_amount }}</span>）：
          </span>
          <div v-if="enableExchange" class="address">
            <Copy :content="platformAddress">
              <template #content>
                <span> {{ platformAddress }} </span>
              </template>
            </Copy>
          </div>
          <div v-else class="!text-#EF0027 address">
            {{ $t('nBr2Lv94yZeV5Q2HIhtBr') }} ！！！
          </div>
          <div class="amount">
            <span class="label">{{ $t("yAEoSDV8NdeDb_W4_dUqx") }} </span>
            <div class="value">
              <span
                class="text-text-blue mr-[10px] text-xl font-bold lt-mobilel:text-[14px]"
              >
                {{ formatAmountText }}
              </span>
              <span class="text-[--first-text] lt-mobilel:text-[14px]">
                {{ fromCrypto.info.symbol }}
              </span>
            </div>
          </div>
        </div>
        <ASpin v-if="enableExchange" :spinning="!qrcode">
          <img
            class="content-qr-code lt-mobilel:hidden"
            :src="qrcode"
            :alt="$t('a4SWgMCv77ZY9kUzCdz_v')"
          >
        </ASpin>
      </div>
      <template v-if="fromCrypto.value">
        <span class="hint">
          {{ $t("ZAW_8xCQ9DyEig2tkz3Uj") }}
          {{ toCrypto.value }}
          {{ toCrypto.info.symbol }}
        </span>
      </template>
    </div>

    <ATooltip :open="copied" :mouseLeaveDelay="0">
      <template #title>
        {{ copied ? $t("UkodznCuNnWJZpOTkayKf") : $t("YkMKVLIaai6CjX6u6lYlg") }}
      </template>
      <AButton
        class="copy comfirm-btn"
        data-test-id="8voa"
        @click="handleCopyAmount"
      >
        {{ $t("TXkHihVAhUA_t5ZItHPGX") }}
      </AButton>
    </ATooltip>
  </div>
</template>

<style scoped lang="scss">
:deep(.ant-select-selector){
  height: 40px !important;
}

.ant-input-number-input{
  font-weight: bold;
}

.transfer-pay {
  --uno: 'mt-[20px]';

  .switch-currency-button {
    --uno: 'w-[30px] h-[30px] mx-[10px] my-[20px] text-[--highlight] cursor-pointer';
  }

  .from-currency,
  .to-currency {
    --uno: 'bg-[#13192e] rounded-[6px] p-[5px] lt-mobilel:p-[15px]';

    & > * {
      --uno: 'flex justify-between items-center p-[15px] lt-mobilel:p-0';
    }

    .balance {
      --uno: 'text-[--secondary-text] lt-mobilel:text-sm lt-mobilel:pb-[15px]';
    }

    .input {
      --uno: 'pt-0 pr-[15px] pb-[5px] pl-[5px] lt-mobilel:p-0';

      .select-currency {
        .currency-item {
          --uno: 'flex items-center text-[white] lt-mobilel:text-14px';
        }

        .select-down-icon {
          --uno: 'text-[#c8d0df]';
        }
      }

      .input-clear {
        --uno: 'cursor-pointer text-[#9498a1] text-20px';
      }
    }
  }

  .shortcut-select {
    --uno: 'p-t-[10px] flex justify-start';
    --uno: 'lt-mobilel:pl-[0] lt-mobilel:pr-[0] lt-mobilel:pt-[10px]';

    & > * {
      --uno: 'mr-[8px] text-xs text-[--secondary-text] border-[1.5px]  px-[30px] py-[7px] rounded-[8px] transition-all duration-0.5 ease-in-out cursor-pointer';
      --uno: 'lt-mobilel:text-[#6f8eca] lt-mobilel:px-[10px] lt-mobilel:py-[7px] lt-mobilel:px-17px lt-mobilel:border-rd-8px text-13px';

      border: 1.5px solid rgb(128 152 191 / 50%);;
    }

    & > *:hover {
      --uno: 'border-[#266ef1] text-[#266ef1]';
    }
  }

  .teaching-link {
    --uno: 'mt-[15px] leading-[22px] text-#8098bf text-16px lt-mobile:text-12px';

    @media bp-lt-mobilel {
      & > .pc-course {
        --uno: 'hidden';
      }
    }

    @media bp-lt-mobilel {
      & > .mobile-course {
        --uno: 'hidden';
      }
    }

    .example-title {
      --uno: 'ml-[6px] text-[--highlight] cursor-pointer';
    }
  }

  .pay-info {
    --uno: 'border-[1px] rounded-[6px] mt-[20px]';

    .content {
      --uno: 'mobilel:flex mobilel:items-center mobilel:justify-between';

      padding-top: 22px;
      border-top: 1.5px dashed #273564;

      .content-text {
        --uno: 'lt-mobilel:w-[100%]';

        width: 70%;

        .address-label {
          --uno: 'text-#8098bf text-16px lt-mobile:text-12px';
        }

        .address {
          --uno: 'text-#8098bf text-16px lt-mobile:text-12px';
          --uno: 'text-sm text-[#7f8fc8] flex items-center mt-[10px]';

          word-break: break-word;

          span {
            width: 100%;
          }

          :deep(.icon) {
            --uno: 'box-content text-base pl-[5px]';
          }
        }

        .amount {
          --uno: 'lt-mobilel:h-40px lt-mobilel:text-xs';
          --uno: 'bg-[--bg-one] rounded-[10px] flex justify-between items-center p-[15px] mx-[0] my-[15px] text-[#7f8fc8]';

          .label {
            --uno: 'mr-[10px] text-[#7f8fc8] text-16px';
            --uno: 'lt-mobilel:text-[#e2e8f2] lt-mobilel:text-14px';
          }

          .value {
            --uno: 'text-[#566897] lt-mobilel:text-[#707582]';
          }
        }
      }

      .content-qr-code {
        --uno: 'w-[103px] h-[103px] lt-mobilel:hideen';
      }
    }

    .hint {
      --uno: 'leading-[1.4em] text-[#26a17b]';
      --uno: 'lt-mobilel:leading-[1.2em] lt-mobilel:text-xs';
    }
  }

  .copy {
    --uno: 'mt-30px w-[100%]';
    --uno: 'lt-mobilel:mt-20px lt-mobilel:border-2px';
  }
}

// 修改默认数字输入框样式
:deep(.ant-input-number) {
  font-size: 22px;
  color: var(--text-primary-color);
  background-color: var(--bg-lesser-color);

  input::placeholder{
    font-size: 16px;
    color: #bfbfbf;
  }
}

// 修改默认输入框样式
:deep(.ant-input) {
  font-size: 16px;
  color: var(--text-primary-color);
  background-color: var(--bg-lesser-color);

  &::placeholder{
    color: var(--text--secondary-color);
  }
}
</style>
